import React from 'react'
import { useState } from 'react';
import { Modal, Button, Typography, Fade, Backdrop, Rating, Divider  } from '@mui/material'
import { Box } from '@mui/system';
import Cookies  from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

const RATE_API = process.env.REACT_APP_RATE_API;
const API_KEY = process.env.API_KEY;
export default function RateForm({ doc, resetCollege, handleSnackOpen }) {

    const [teaching, setTeaching] = useState(0);
    const [communication,setCommunication] = useState(0);
    const [attendance, setAttendance] = useState(0);
    const [marks, setMarks] = useState(0);

    // Cookie configuration
    const getUserId = () => {
        let userId = localStorage.getItem('userId');
            if (!userId) {
            userId = uuidv4();
            localStorage.setItem('userId', userId);
            }
            return userId;
    };
    const setRatedCookie = (itemId, userId) => {
        const cookieName = `rated_${itemId}_${userId}`;
        const cookieValue = 'true';
        if (!Cookies.get(cookieName)) {
            Cookies.set(cookieName, cookieValue, { expires: 400 });
        }
    };
    const hasRatedItem = (itemId, userId) => {
        return Cookies.get(`rated_${itemId}_${userId}`) === 'true';
    };

    //  Submission Form
    const submitHandler = async (event) =>{
        event.preventDefault();
        handleClose();
        resetCollege();
        setRatedCookie(doc._id, getUserId());
        handleSnackOpen();
        const response = await fetch(`${RATE_API}${doc._id}`, {
            method:'POST',
            headers: {'Content-Type': 'application/json','Authorization': `Bearer ${API_KEY}`},
            body: JSON.stringify({teaching, communication, attendance, marks})
        })
        console.log(response);
    };

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 330,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        borderRadius:'15px'
    };

    return (
        <div>
        {
            hasRatedItem(doc._id, getUserId())?
            <Button className="disabled" sx={{width:'100%',borderRadius:'10px',backgroundColor:'#635985'}} disabled >
                <Typography variant='h6' color='white'>
                    تم التقييم
                </Typography>
            </Button>
        :
        <Button onClick={()=>{handleOpen()}}   sx={{width:'100%',borderRadius:'10px',backgroundColor:'#443C68', ":hover":{backgroundColor:'#635985'}}}  >
                <Typography variant='h6' color='white'>
                    قيّم
                </Typography>
            </Button>
            }            
            <Modal
                dir='rtl'
                open={open}
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                backdrop: {
                    timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <form onSubmit={submitHandler}>
                        <Box sx={style}>
                            <Typography sx={{mb:5}} align='center' variant='h5' color='primary'>
                                أستمارة التقييم
                            </Typography>
                            <Typography  sx={{mb:3}} variant='h5' color='text.primary'>
                                {doc.Fname}
                            </Typography>
                            <Divider/>
                            
                            <Typography variant='h6'>الشرح</Typography>
                            <Rating dir='ltr' 
                                value={teaching} 
                                name="teaching"
                                precision={0.5}
                                onChange={(event) => setTeaching(parseFloat(event.target.value))} 
                                />
                            <Divider/>
                            <Typography variant='h6'>التعامل</Typography>
                            <Rating dir='ltr'
                                value={communication} 
                                name='communication' 
                                precision={0.5} 
                                onChange={(event) => setCommunication(parseFloat(event.target.value))}
                                required 
                                />
                            <Divider/>
                            <Typography variant='h6'>التحضير</Typography>
                            <Rating dir='ltr' 
                                value={attendance} 
                                name='attendance' 
                                precision={0.5}
                                onChange={(event) => setAttendance(parseFloat(event.target.value))}
                                />
                            <Divider/>
                            <Typography variant='h6'>الدرجات</Typography>
                            <Rating dir='ltr'
                                value={marks} 
                                name='marks' 
                                precision={0.5}
                                onChange={(event) => setMarks(parseFloat(event.target.value))}
                                />
                            {
                            teaching===0 || marks===0 || attendance===0|| communication===0?
                                <Button 
                                sx={{
                                    mt:4,
                                    width:'100%',
                                    borderRadius:'10px',
                                    backgroundColor:'#635985'
                                    }}
                                    disabled
                                    >
                                <Typography variant='h6' color='white'>
                                    حفظ
                                </Typography>
                                </Button>
                            :
                                <Button 
                                sx={{mt:4,
                                    width:'100%',
                                    borderRadius:'10px',
                                    backgroundColor:'#443C68',
                                    ":hover":{backgroundColor:'#635985'}
                                    }}
                                    type="submit"
                                    >
                                    <Typography variant='h6' color='white'>
                                        حفظ
                                    </Typography>
                                </Button>
                            }
                        </Box>
                    </form>
                </Fade>
            </Modal>
        </div>
    )
}