import React from 'react'
import { useState, useEffect } from 'react';
import RateForm from './RateForm';
import Fuse from 'fuse.js';
import { List, Grid, CircularProgress,
        Container, Card, CardContent,
        Typography , ListItemText,Divider, 
        Rating, Pagination, TextField } from '@mui/material/';

const DOCS_API = process.env.REACT_APP_DOCS_API; 
const API_KEY  = process.env.REACT_APP_API_KEY;

export default function DocCard({ setCurrentCollege, currentCollege, setSnack }) {

    const [docs, setDocs] = useState([])
    const [searchTerm, setSearchTerm] = useState('');

    useEffect( ()=> {
        docsFitch(currentCollege);
        setCurrentPage(1);
    },[currentCollege])
    
    
    const searchHandler = (event) => {
        const input = event.target.value;
        const arabicRegex = /[\u0600-\u06FF]/;
        if (!arabicRegex.test(input)) {
            setSearchTerm('');
            return;
        }
        setSearchTerm(input);
        setCurrentPage(1);
    };

    const resetCollege = ()=>{
        setCurrentCollege('');
    }
    
    const handleSnackOpen = ()=>{
        setSnack(true)
    }

    const docsFitch = async (college) => {
        try{
            if(college !== ''){
                const response = await fetch(`${DOCS_API}${college}`,{
                    headers: {
                        'Authorization': `Bearer ${API_KEY}`
                    }
                });
                const data  = await response.json();
                setDocs(data);
            }
        }catch(err){console.log(err)}
    }
    
    //  Search
    const options = {
        keys: [
            { name: 'name_1', weight: 0.3 },
            { name: 'name_2', weight: 0.1 },
            { name: 'name_3', weight: 0.2},
            { name: 'name', weight: 0.4 }
        ],
        threshold: 0.3, 
    };
    const fuse = new Fuse(docs, options);
    const results = fuse.search(searchTerm);
    const finalRslt = searchTerm? results.map(r => r.item) : docs;
    
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage= 9;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = finalRslt.slice(indexOfFirstItem, indexOfLastItem)

    return (
        <>
        <Container dir='rtl' maxWidth='md'>
            <TextField placeholder='البحث باللغة العربية' value={searchTerm}
                        onChange={searchHandler} 
                        fullWidth  
                        label="بحث" 
                        InputLabelProps={{
                            dir: 'rtl'
                        }}
                        id="fullWidth" 
                        sx={{borderRadius:'30px', mb:5, mt:1}}/>
        </Container>
        {docs.length>0?'':<CircularProgress sx={{position:'relative', mt:'15%'}} color="primary" />}
        <div className='doc' >
            <Container  sx={{p:4}}   maxWidth='lg'>
                <Grid container spacing={12}>
                    {currentItems.map((doc)=>(
                        <Grid key={doc._id} item  xs={12} sm={12} md={4}>
                            <Card  sx={{ maxWidth: 345 , minWidth:200, minHeight:350 }}>
                                    <CardContent align='right' sx={{ flexGrow: 1 }}>
                                        <Typography sx={{width:'100%'}}  color={'primary'} align='center'  variant="h5" >
                                            {doc.name}
                                        </Typography>
                                        <List variant="body2" color="text.secondary">
                                            <ListItemText  align='center'>
                                                <Typography variant='h4'  color="primary">
                                                    <span style={{fontWeight:'bold'}}>{doc.total}</span>
                                                    <span style={{fontSize:'17px'}}>/5</span> 
                                                </Typography>
                                                    <Rating  value={parseFloat(doc.total)} precision={0.5} readOnly size="large"/>
                                            </ListItemText >
                                            <ListItemText align="center">
                                                <Typography color="text.secondary">
                                                عدد التقييمات {doc.number_of_rate} 
                                                </Typography>
                                            </ListItemText>
                                            </List>
                                            <Divider sx={{mb:1}}/>
                                            <Grid align="right" container spacing={0}>
                                                <Grid item xs={6}>
                                                    <Rating value={parseFloat(doc.teaching)} precision={0.5} readOnly size="small"/>   
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography variant='h6' color="text.secondary">شرح
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Rating value={parseFloat(doc.communication)} precision={0.5} readOnly size="small"/>   
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography variant='h6' color="text.secondary">تعامل
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Rating value={parseFloat(doc.attendance)} precision={0.5} readOnly size="small"/>   
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography variant='h6' color="text.secondary">تحضير
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Rating value={parseFloat(doc.marks)} precision={0.5} readOnly size="small"/>   
                                                </Grid>
                                                <Grid item xs={4}>
                                                <Typography variant='h6' color="text.secondary">درجات
                                                </Typography>
                                                </Grid>
                                            </Grid>
                                            <Divider sx={{mt:1}}/>
                                                <RateForm handleSnackOpen={handleSnackOpen} resetCollege={resetCollege} doc={doc}/>
                                    </CardContent>
                            </Card>
                        </Grid>))}
                </Grid>
                {docs.length >0?
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Pagination color="primary" 
                                sx={{mt:10}} siblingCount={0}  
                                size="small"
                                count={Math.ceil(finalRslt.length / itemsPerPage)}  
                                onChange={(event,value)=>{setCurrentPage(value)}}/>
                    </div>
                :''}
            </Container>
        </div>
        </>
    )
}

