import React, { useState } from 'react';
import './App.css';
import College from './components/College';
import DocCard from './components/DocCard';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Toolbar, Box, Typography,
        Drawer,  Divider, IconButton, CssBaseline,
        ThemeProvider, createTheme, Container, Snackbar } from '@mui/material/';

function App() {
  const [currentCollege, setCurrentCollege] = useState('');
  const [mobileOpen, setMobileOpen] = useState(false);
  const [snack, setSnack] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div dir="rtl" > 
      <Toolbar  > 
        <Typography variant='h6' color="primary">الكليات</Typography>
      </Toolbar>
      <Divider  />
      <College setMobileOpen={setMobileOpen} setCurrentCollege={setCurrentCollege} />
    </div>
  );
  
  const drawerWidth = 300;
  const theme = createTheme({
    typography: {
        fontFamily: 'Tajawal',
      },
      palette: {
        primary: {
          main: '#443C68',
        },
        secondary: {
          main: '#635985',
        },
      },
  });

  const handleSnackClose = () => {
    setSnack(false);
  };

  return (
    <>
    <div className="App">
    <ThemeProvider theme={theme}>
      <Snackbar dir='rtl'
        open={snack}
        vertical={'bottom'}
        horizontal={'center'}
        onClose={handleSnackClose}
        message="تم التقييم بنجاح"
      />
    <Box sx={{ display: 'flex' }}>
    <CssBaseline />
      <AppBar 
        color="primary"
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          mr: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar  sx={{ display: 'flex' }}>
            <a href='/' style={{textDecoration:'none', color:'inherit'}}>
              <Typography sx={{fontFamily:'Alkatra'}} variant="h5" noWrap component="div">
                  Proffy
              </Typography >
                </a>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            sx={{ ml: 'auto', display: { sm: 'none' } }}
            >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Box>
        <Drawer
          anchor= "right"
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, 
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          anchor= "right"
          variant="permanent"
          sx={{ 
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        align={'center'}
        sx={{height:'100%', mr: { sm: `${drawerWidth}px`, 
            display:'-ms-grid'}, flexGrow: 1, p: 3,
            width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
        {
          currentCollege?
          <Box>
            <Container dir='rtl' maxWidth='md'>
                <Typography
                    sx={{mb:'5px'}}
                    variant="h4"
                    align="right"
                    color="primary"
                    gutterBottom
                >
                  {currentCollege}
                </Typography>
            </Container>
            <div className='docs'>
                <DocCard setSnack={setSnack} currentCollege={currentCollege} setCurrentCollege={setCurrentCollege}/>
            </div>
            </Box>
            
        :
        <>
          <Typography variant="h6" align="center" color="text.secondary" paragraph>
            منصة تقييم أعضاء هيئة التدريس بجامعة الإمام محمد بن سعود الإسلامية         
          </Typography>
          <Typography sx={{fontWeight: 'bold'}} variant="h6" align="center" color="primary" paragraph>
            ابدأ باختيار الكلية من القائمة
          </Typography>
          
          <IconButton
            align='center'
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            sx={{top:'50px', pt:1.5, ml: 'auto', display: { sm: 'none' }, borderRadius:'10px',backgroundColor:'#443C68', ":hover":{backgroundColor:'#635985'} }}
            >
            <Typography sx={{fontSize:'17px'}} variant='h6' color='white'>الكليات</Typography> 
          </IconButton> 
          
          <Typography sx={{position: 'absolute', bottom: '0'}} align="center" color="text.secondary" variant='p' paragraph>
          .المنصة غير رسمية وليست تابعة لجامعة الإمام 
          </Typography>
        </>
        }
      </Box>
    </Box>
    </ThemeProvider>
    </div>
    
    </>
  );
}

export default App;