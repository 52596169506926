import React from 'react';
import {List, ListItemButton, ListItemText, ListItem, CircularProgress, Box} from '@mui/material/';
import { useState, useEffect } from 'react';

const COLLEGES_API = process.env.REACT_APP_COLLEGES_API ;
const API_KEY  = process.env.REACT_APP_API_KEY;
export default function College({ setMobileOpen, setCurrentCollege }) {

    const [colleges, setColleges] = useState([]);
    
    const collegesFetch = async ()=>{
        try {
            const response = await fetch(`${COLLEGES_API}`,{
                headers: {
                    'Authorization': `Bearer ${API_KEY}`
                }
            });
            const data = await response.json();
            setColleges(data);
        }catch(error){console.log(error)}
    }

    useEffect( ()=> {
        collegesFetch();
    },[])

    function handleClick(college){
        setCurrentCollege(college);
        setMobileOpen(false);
        window.scrollTo({ top: 0, behavior: "smooth" });
    }

    return (
        <>
        <List>
        {colleges.length>0?null:
        <Box height={'800px'}>
            <ListItem>
                    <CircularProgress sx={{position:'relative', mr:'40%', mt:'50%'}}  color="secondary" />
            </ListItem>
        </Box>
            }
            {
                colleges.map((college)=>(
                    <ListItem key={college._id}>
                    <ListItemButton  className='button' onClick={()=>{ handleClick(college.name) }}> 
                        <ListItemText  align='right' primary={college.name} />
                    </ListItemButton> 
                </ListItem>
            ))
        }
        </List>
        </>
    )
}
